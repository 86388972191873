<script setup lang="ts">

import { usePage } from '@inertiajs/vue3'
import { Link } from '@inertiajs/vue3'
import CardHostelsOtaLinks from '@/Pages/the-slp/components/the-shortcodes/card-hostels/CardHostelsOtaLinks.vue'
import { OtaLinks } from '@/Interfaces/Common'
import CardHostelsEditLinks from '@/Pages/the-slp/components/the-shortcodes/card-hostels/CardHostelsEditLinks.vue'
import { EditLinks, SlpSlider } from '@/Pages/the-slp/components/interfaces/TheSlp'
import SeoTable from '@/Pages/the-slp/components/the-shortcodes/SeoTable.vue'
import CardHostelsSlider from '@/Pages/the-slp/components/the-shortcodes/card-hostels/CardHostelsSlider.vue'
import TheMap from '@/Pages/the-slp/components/the-shortcodes/TheMap.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBed, faStar } from '@fortawesome/free-solid-svg-icons'
import { MapData } from '@/Interfaces/Map'

defineProps<{
    city: string
    items: {
        idAttr: string
        name: string
        rating: string
        specialText: string
        url: string
        otaLinks: OtaLinks
        editLinks?: EditLinks
        slider: SlpSlider
        price: {
            dorm?: string
            priv?: string
        }
    }[]
    seoTable: {
        title: string
        items: []
    }
    map: {
        city: string
        items: MapData
    }
}>()

const page = usePage()

</script>

<template>
    <div v-if="items.length" class="listingsList pt-3">
        <div class="row vue-full-card-slider-wrap">
            <div v-for="(item, key) in items" :key class="col-lg-12 mb-5 listing listingLink listing">
                <h2 :id="item.idAttr" class="card-title h3">
                    <span class="icon-rounded bg-dark mb-3 text-white icon-rounded-sm">
                        {{ key + 1 }}
                    </span> {{ item.name }}
                </h2>

                <p class="article-metadata">
                    <span v-if="item.price" class="mr-4"><FontAwesomeIcon :icon="faBed" /> Rooms from {{
                        item.price
                    }}</span>

                    <span class="mr-4"><FontAwesomeIcon :icon="faStar" class="text-warning" /> Rating: <span
                        class="font-weight-bold"
                    >{{ item.rating }}</span></span>
                </p>

                <div v-if="item.specialText" class="mb-3" v-html="item.specialText" />

                <p>
                    Compare all prices and availability for
                    <Link :href="item.url" target="_blank">
                        {{ item.name }}
                    </Link>
                    on Booking portals with 1 click on Hostelz.com
                </p>

                <CardHostelsEditLinks v-if="item.editLinks && !!page.props.user" :="item.editLinks" />

                <CardHostelsOtaLinks :="item.otaLinks" />

                <CardHostelsSlider :="item.slider" />
            </div>
        </div>
    </div>

    <TheMap :="map" />

    <SeoTable :="seoTable" />
</template>

<style scoped lang="scss">

</style>